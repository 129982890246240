<template>
    <v-footer>
        <v-card elevation="0" rounded="0" width="100%" class="bg-black text-center">
            <v-card-text>
               
                <v-btn class="mx-4"
                icon="fab fa-x share" varient="plain" color="white" :href="'https://x.com/salahdevx'" target="_blank">
                </v-btn>
                <v-btn class="mx-4"
                icon="fab fa-facebook share" varient="plain" color="white" :href="'https://www.facebook.com/salahdevx/'" target="_blank">
                </v-btn>
                <v-btn class="mx-4"
                icon="fab fa-instagram share" varient="plain" color="white" :href="'https://www.instegram.com/salahdevx/'" target="_blank">
                </v-btn>
                <v-btn class="mx-4"
                icon="fab fa-linkedin share" varient="plain" color="white" :href="'https://www.linkedin.com/in/salaheldeen-musa-74887a16b/'" target="_blank">
                </v-btn>
            </v-card-text>

        <v-card-text elevation="0" rounded="0" class="bg-black text-center">
                @{{ new Date().getFullYear() }}-
                <strong> All Rights Reserved</strong>
        </v-card-text>
    </v-card>

    </v-footer>
</template>
<script >
export default {
    name:"FooterViewv"
}
</script>

<style>
.v-footer{
    /* padding: 8px 0 !important; */
    align-self: center;
    width: 100%;
}
</style>