<template>
  <v-app id = 'home'>
    
    <NavBar />

    <v-container fluid>
      <div class="head">
        <title>Salahdev</title>
        <v-row>
          <v-col cols="5">
          <div style="position: relative" class = "mt-16">
          <h1 class ="text-grey"> Hello,</h1>
          <h1 class = "text-white"> I'M Salah Mohammed</h1>
          <span class="text-grey">  Full-Stack  developer</span><br>
          <v-btn title dark class="text-yellow mt-8" variant = 'outlined'>Contact me</v-btn>

          </div>
          </v-col>
         <v-col cols="2">
          <div style="position: absolute;z-index: 9999;bottom: 0;margin-left: auto;margin-right: auto;right:0;left: 0;text-align: center;">
         <v-icon>fas fa-angle-double-down</v-icon>
            </div>
             </v-col>
           <v-col cols="5">
           <div style="position: relative;z-index: 9999;" class='mt-16'>
            <v-img src="salahdev.png" contain max-height = "400"></v-img>
             </div>
        </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="mt-16" id = "about"> 
          <div>
            <v-row>
              <v-col cols="12" sm ="6" > 
                <div class = "egg">
                  <v-img src="salahdev-about.png" contain max-height = "300"></v-img>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
              <h2 class="mt-16">About Me</h2>
              <div style="width: 120px;">
                <v-slider v-model="slider2" color="black" >
                </v-slider>
              </div>
              <h4 class="mt-n4">I Am a Creative Web/Mobile Desinger</h4>
              <h4>And Developer</h4>
              <p class = "text-grey">I am a highly motivated and detail-oriented Software Engineer with a strong passion for mobile application development. Over the past 5 years, I have honed my skills in Flutter, Nodejs,VueJs and WordPress frameworks, demonstrating proficiency in creating dynamic and innovative Android, IOS and web applications. My expertise encompasses the entire mobile and web development lifecycle, from conceptualization to deployment, and I have a proven track record of contributing to the success of diverse projects.</p>
            <br>

            <v-btn tile dark color="yellow" class="mt-4"  :href="'https://drive.google.com/file/d/1Jp90YXag_zFddsn5g3KAeuhmuWGOaU5Z/view?usp=drive_link'" target="_blank">

              Download Resume
            </v-btn>

            </v-col>
            </v-row>
          </div>
        </v-col>
        <div class="text-center mt-4">
          <h2>What I Do</h2>
          <div style="width: 120px;margin: 0 auto;">
          <v-slider v-model="slider2" color="black"></v-slider>
          </div>
        </div>

        <v-col cols="12" class="padd" id="portfolio">
          <div class="first" id="project">
            <v-row>
              <v-col cols="12">
                
                <div class="child">
                    <v-btn icon="fas fa-laptop" color="#FBDF7E" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">
                      Making Systems
                    </h3>
                    <p class="text-grey ml-4 text-caption">
                      In a country like Sudan, I help small businesses <br>
                      by providing Systems solutions helping them to <br>
                      to manage their dialy load and making reports.
                    </p>
                </div>
                <div class="child">
                    <v-btn icon="fas fa-mobile-alt" color="#FBDF7E" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">
                      Making Mobile Apps
                    </h3>
                    <p class="text-grey ml-4 text-caption">
                     Mobile development is a global market  <br>
                      I make mobile applications by flutter and a help of Nodejs<br>
                      to handle the backend the services.
                    </p>
                </div>
                <div class="child">
                    <v-btn icon="fas fa-wifi" color="#FBDF7E" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">
                      I build My Own APIes
                    </h3>
                    <p class="text-grey ml-4 text-caption">
                    I use Nodejs for creating fully functioning APIes to support my front-end <br>
                      I can also use Express framework to make it easer<br>
                      handling all what I want with the power of JS.
                    </p>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </v-col>

        <v-col cols="12" sm="12" id="services">
          <div class="text-center mt-4">
          <h2 class=""> My Recent Works</h2>
          </div>
          <br>
          <div class="d-flex justify-center mb-6">
            <v-btn color="#FBDF7E" class="mr-2">All</v-btn>
            <v-btn  class="mr-2" variant="tonal">Mobile</v-btn>
            <v-btn  class="mr-2" variant="tonal">Desktop</v-btn>
            <v-btn  class="mr-2" variant="tonal">Web</v-btn>
            <v-btn  class="mr-2" variant="tonal">Backend</v-btn>
          </div>
        </v-col>

          <v-col cols="12" class="imgHover">
                <v-row class="fill-height" align = "center" justify="center">
                  <template v-for="(item,i) in items " :key="i">
                    <v-col cols="12" md="5">
                      <v-hover v-slot="{isHovering,props}">
                        <v-card :elevation =" isHovering ?10: 2" class = "{'on-hover' : isHovering}" v-bind="props">
                          <v-card :href="item.url" target="_blank">
                          <v-img :src="item.img" height="255px" cover ></v-img>
                          </v-card>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </template>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12"> 
                <div class="d-flex justify-center mb-6">
                    <v-btn color="#FBDF7E" class="mt-4">Load More</v-btn>
                </div>
           </v-col>
            <v-col cols="12" id="biog">
              <div class="hire">
                <v-row>
                  <v-col cols="12" sm="12">
                    <h1 class="mt-9">Hire me for your Pro project</h1>
                      <p class="text-grey">
                        You'll gain a dedicated professional with a proven track record of excellence. <br>
                         My comprehensive experience in project management, <br>
                          combined with a strong background in software development and a passion for innovation,<br>
                           positions me uniquely to drive your project to success.
                      </p>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn color="FBDF7E" class ="mt-15"> Hire Me</v-btn>
                  </v-col>
                </v-row>
              </div>

            </v-col>
            <v-col clos="12" sm="12" class="px-16" id="contact">
              <v-row>
                <v-col cols="12" sm="4">
                  <div class="child">
                    <h1> Contact Info.</h1>
                    <v-btn icon="fas fa-map-marker-alt" class="mt-10" variant="outlined"></v-btn><br/>
                      <span class="text-caption">Sudan, Port Sudan</span><br>
                      <v-btn icon="fas fa-phone-alt" class="mt-10" variant="outlined"></v-btn><br/>
                      <span class="text-caption">+249126565113</span><br/>
                      <v-btn icon="fas fa-envelope" class="mt-10" variant="outlined"></v-btn><br/>
                      <span class="text-caption">contact@salahdev.top</span><br/>
                      <span class="text-caption">salahsoftcenter@gmail.com</span><br/>
                  </div>
                </v-col>
                <v-col cols="12" sm="8" ><h1> Send your Message</h1>
                  <v-divider></v-divider>
                  <span class="text-caption">
                    If you're looking for someone to bring your ideas to life,
                     you're in the right place. Whether you need a robust system,
                      an engaging mobile app, or a dynamic website, I have the expertise 
                       to deliver high-quality solutions tailored to your needs. Let's discuss how I can help you create something amazing. What project do you have in mind?
                  </span>
    <form v-if="!submitted" @submit.prevent="submitForm">

                  <v-row class="mt-10">
                    <v-col cols="12" sm="6">
                        <v-text-field label="Email" presistent-hint variant="outlined" name="email" v-model="email" class="email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Name" presistent-hint variant="outlined" name="name" class="name" v-model="name"></v-text-field>
                    </v-col>
                  </v-row>


                        <v-textarea label="Message" presistent-hint variant="outlined" name="message" class="message" v-model="message"></v-textarea>
                        <div v-if="submitted">
      <p>Thank you for your message! We'll get back to you soon.</p>
    </div>
    <div v-if="error">
      <p>Error: {{ error }}</p>
    </div>

                      <v-btn color="#FBDF7E" type="submit">SubMit Now!</v-btn>
      <!-- <button type="submit" color="#FBDF7E">Submit</button> -->

    </form>

      
    
    
     
   
   

                </v-col>
              </v-row>

            </v-col>

    </v-container>
    <FooterViewv />


  </v-app>
</template>

<script>

document.title = 'SalahDev'
import axios from 'axios'

import NavBar from '@/components/NavBar.vue';
import FooterViewv from '@/components/FooterViewv.vue';




import { defineComponent } from 'vue';

// Components


export default defineComponent({
  name: 'HomeView',
setup(){
  return {
    slider2: 50,
    items:[
      {img:"english-go.jpg",url:"https://play.google.com/store/apps/details?id=com.eduness.www"},
      {img:"quizat-poster.webp",url:"https://play.google.com/store/apps/details?id=com.Quizat.www"},
      {img:"alsaheil centre.png",url:"https://play.google.com/store/apps/details?id=net.alsaheil.www"},
      {img:"Emdad App.png",url:"https://play.google.com/store/apps/details?id=com.emdad_app.www"},

      
      
      
    ]
  }
},

  components: {
      FooterViewv, NavBar,
  },
  data() {
    return {
      email: '',
      message: '',
      submitted: false,
      error: '',
      endpoint: 'https://formcarry.com/s/YCjyjedjeQb',
    }
  },
  methods: {
    async  getResume() {
       
   },
    async submitForm() {
      const data = {
        email: this.email,
        name:this.name,
        message: this.message,
      }
      try {
        const response = await axios.post(this.endpoint, data)
        if (response.data.code === 200) {
          this.submitted = true;
        } else {
          this.error = response.data.message;
          console.log(response.data.message)
        }
      } catch (err) {
        this.error = err.message;
      }
    },
  },

  
});
</script>
<style scoped>
/* eslint-disable */
 .v-container{
  padding:16px 0,
 }
 .head {
  position: relative;
  text-align: center;
  padding:12px;
  margin-bottom:6px ;
  height: 400px;
  width: 100%;
  color: white;
 }
 .head:before{
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height:100%;
width: 50%;
background: black;
transform: skew(0deg, 6deg);
 }
 .head:after{
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  height:100%;
width: 50%;
background: black;
transform: skew(0deg, -6deg);
 }
 .egg{
  display: block;
  margin-left:100px;
  margin-top:50px;
  width: 356;
  height: 300px;
  background-color: #FBDF7E;
  border-radius: 50% 50% 50% 50%/ 60% 60% 40% 0%;;
 }
 .first{
width: 100%;
height: 17%;
text-align: center;
padding: 2rem 2rem;
 }
 .child{
  display: inline-block;
  padding: 2rem 2rem;
  vertical-align: middle;
  text-align: center;
  margin-right:8px;
  margin-left:8px;

 }
 .imgHover{
  padding: 1% ;
 }
 .pre{
  width: 100%;
  height: 380px;
  text-align: center;
  padding: 0 200px;
  background-color: #f5f5f5;
 }
 .hire{
  width: 100%;
  height: 10%;
  padding:  5%;
  background-color: #e9e9e9;
  margin-top: -24px;
 }
 </style>
