<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  head: {
       link: [
             {
                 rel: "icon",
                 href: require("../public/salahdev.png")
             },
          ]
       },
  data: () => ({
    //
  }),
}
</script>
