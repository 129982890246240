<template>
    <v-app-bar app color = 'black' dark flat class = 'px-12'
>
<v-btn>
    <v-icon color="yellow" left class = "mr-2">fas fa-computer</v-icon>
        SalahDev
        </v-btn>
        <v-spacer></v-spacer>
        <div class ="bg-black py-3.5 px -6 shadow md:flex justify-between">
        <ul class="md:flex md:items-center md:px-0 px-10 md:pb-0 pb-10 md:static absolute bg white">
        <template v-for="(item,i) in items " :key="i">

                      <v-hover v-slot="{props}">
       <li class="md:mx-4 md my-6" > <v-btn text @click = "scroll(item.link)"   v-bind="props">{{ item.name }}</v-btn></li>
                      </v-hover>

                  </template>
                </ul>
            </div>

</v-app-bar>
</template>
<script>
export default {
    setup(){
  return {
    slider2: 50,
    items:[
    {name:"Home",link:"home"},
    {name:"About",link:"about"},
    {name:"Portfolio",link:"portfolio"},
    {name:"My Works",link:"services"},
    {name:"Biog",link:"biog"},
    {name:"Contact",link:"contact"}

      
      
      
    ]
  }
},
methods:{scroll(refName){
    const element = document.getElementById(refName);
    element.scrollIntoView({behavior:"smooth"});
}}
}</script>
<style>
</style>